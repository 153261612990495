<style>

#contenu {
    font-family: 'Source Sans Pro','Avenir', Helvetica, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  color: #343434;

  background-color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  margin-top: 26rem;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 500px;
}
#logo{
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  padding: 0rem 1rem;
  filter: invert(1);
}
h1{
  text-align: left;
  /* border-bottom: 9px solid black; */
  /* padding-bottom: 1rem; */
  margin-bottom: 0rem;
  max-width: 42rem;
  text-wrap: balance;
}
h3{
  /* opacity: 0.8; */
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
b{
  font-weight: 600;
}
.sous-titre{
  font-weight: 100;
}

#introduction{
  columns: 2;
  margin-bottom: 4rem;
  margin-top: 1rem;
  column-gap: 4rem;
}
li{
  margin-bottom: 1rem;
}
#introduction p{
  margin-top: 0;
}
#demonstrateur{
    /* position: fixed;
    bottom: 0;
    right: 0;
    margin: 1rem; */
    background-color: black;
    /* border-radius: 6px; */
    padding: 7px 20px;
    color: white;
    /* font-weight: bold; */
    text-transform: uppercase;
    margin-top: 1rem;
    display: inline-block;
}

#bannière{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 20rem; */

}
</style>


<template>
  <div id="contenu">
    <ParlezAmi />
    <img src="/images/bannière.jpg" id="bannière">
    <img src="/images/logo.png" id="logo">
    
    
    <h1>Restauration scolaire : quels effets des leviers de transition sur le coût d'un repas ?</h1>
    <p class="sous-titre">Outil d'analyse des coûts et des leviers de transition vers une alimentation plus saine et durable.</p>
    <span id="demonstrateur">Démonstrateur</span>

    <h3>Démonstrateur prototype à ne pas diffuser</h3>
    <div id="introduction">
    <p>Cet outil vous permet d'analyser les effets des leviers de transition sur le coût complet d'un repas (coûts comptables en € TTC et coûts sociétaux décrits de manière qualitative, quantitative ou monétaire).<br>
    Les leviers de transition pris en compte dans cet outil découlent des lois EGALIM et Climat.<br>
    L'effet des leviers de transition sur les coûts est calculé à partir d'une situation moyenne de référence (pour une cantine similaire à la votre). Ainsi si vous avez déjà entamé une transition et que vous remplissez les leviers correspondants, des coûts additionnels (positifs ou négatifs) de transition apparaissent déjà à gauche du coût total. Vous pouvez ensuite jouer avec les différents paramètres pour estimer les coûts supplémentaires de l'activation des différents leviers de transition dans votre cantine.
    <br>
    <br>
    </p>
    </div>


    <DecoupeDeCouts
      v-if="route=='decoupe-de-couts'"
      v-on:navigation="naviguer($event)"
    />
    <CoutsSocietaux
      v-if="route=='couts-societaux'"
      v-on:navigation="naviguer($event)"
    />
    <!-- <Formulaire />
    <Publications /> -->
  </div>
</template>
<script>
import DecoupeDeCouts from './components/decoupe-de-couts.vue'
import CoutsSocietaux from './components/couts-societaux.vue'
import ParlezAmi from './components/parlez-ami.vue'
import "./css/tooltip.css";


export default {
  name: 'app',
  data() {
    return {
      route: 'decoupe-de-couts'
    }
  },
  components: {
    DecoupeDeCouts,
    CoutsSocietaux,
    ParlezAmi,
  },
  methods: {
    naviguer(route){
      this.route = route
    }
  }
}
</script>
