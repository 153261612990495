<style scoped>
.diagramme-en-barres{
  width: 270px;
  background-color: white;
}
#total{
  font-size: 29px;
  /* width: 300px; */
  border-bottom: 10px solid #f2f2f2;
  color: #9A9A9A;
  height: 29px;
  /* margin-bottom: 6px;; */
}
#total_libelle{
  /* float: right; */
}
#total_valeur{
  float: right;
  font-weight: 600;
}

.element{
  position: relative;
  transition: 0.3s;
  margin-bottom: 10px;
  /* box-sizing: border-box; */
  cursor: pointer;
}
.element:hover{
  filter: brightness(1.05);
  /* border: 5px solid white inset; */
}
.ligne{
  top: 6px;
  position: absolute;

}

.libelle{
  left: 9px;
  font-weight: 600;
  opacity: 0.8;
  max-width: 150px;
}
.libelle::first-letter {
  text-transform: uppercase;
}
.valeur-secondaire{
  /* left: 125px; */
  right: 60px;
  text-align: center;
  /* opacity: 0.5; */
  font-weight: 600;
  color: rgba(74, 74, 85, 0.50);
  z-index: 11;
}

.valeur{
  right: 9px;
  opacity: 0.8;
  z-index: 10;
}
.element.petit .ligne{
  top: 0;
}
.detail_couleur{
  position: absolute;
  left: calc(100% + 11px);
  width: 77px;
  height: 100%;
  /* background-color: rgb(203, 203, 203); */
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: 0.3s;
}
.detail_texte{
  position: absolute;
  left: calc(100% + 89px);
  width: 200px;
  height: 100%;
  /* background-color: rgb(203, 203, 203); */
  display: flex;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
  /* font-weight: 100; */
  color: #4A4A55;
  opacity: 0;
  transition: 0.3s;
}
.sous_element_couleur{
  transition: 0.3s;
}
.sous_element{
  padding: 0 5px;
}
.valeur_detail{
  float: right;
}
.detail_texte.petit{
  display: block;
  position: relative;
  top: -58%;
  width: 500px;
  
}
.detail_texte.petit .sous_element{
  display: inline-block;
  position: relative;

}

.detail_texte.petit .valeur_detail{
  margin-left: 9px;

}
.element:hover .detail_texte, .element:hover .detail_couleur{
  opacity: 1;
}
.afficher_le_detail .detail_texte,.afficher_le_detail .detail_couleur{
  opacity: 1;
}

.positif{
  color:rgba(255, 0, 0, 0.601);
}
.negatif{
  color: rgba(0, 128, 0, 0.599);
}

</style>
<template>
<div class="diagramme-en-barres" :class="{afficher_le_detail}">
  <div id="total">
    <span id="total_libelle">Coût</span>
    <span id="total_valeur">{{Number(total).toFixed(2).toString().replace('.',',')}} {{unite}}</span>
  </div>
  <div
    v-for="(element, index) in elements"
    v-bind:key="element.libelle"
    class="element"
    :class="{ petit: element.valeur * parseFloat(pixelsParUnite) < 40 }"
    :style="{
      height: (element.valeur * parseFloat(pixelsParUnite)) + 'px',
      backgroundColor: element.couleur,
      zIndex: -1*index+10000
    }"
    @click="basculer_affichage()"
  >
    <div class="libelle ligne">{{element.libelle}}
    </div>
    <span
      v-if="afficherUniteSecondaire"
      :aria-label="element.detail_calcul_valeur_secondaire"
      :role="element.valeur_secondaire != 0 ? 'tooltip':''"
      data-microtip-size="large"
      class="valeur-secondaire ligne"
      :class="{
        positif: element.valeur_secondaire > 0,
        negatif: element.valeur_secondaire < 0
      }"
    >
      {{element.valeur_secondaire > 0 ? '+' : ''}}{{arrondir(element.valeur_secondaire,2)}} {{uniteSecondaire}}
    </span>
    <div
      class="valeur ligne"
      :aria-label="element.detail_calcul_valeur"
      role="tooltip"
      data-microtip-size="large"
    >{{arrondir(element.valeur,2)}} {{unite}}</div>

    <div
      class="detail_couleur"
      :style="{
        backgroundColor: element.couleur
      }"
    >
      <div
        v-for="(sous_element, index_sous_element) in element.sous_elements"
        v-bind:key="sous_element.libelle"
        class="sous_element_couleur"
        :class="{ petit: sous_element.valeur * parseFloat(pixelsParUnite) < 40 }"
        :style="{
          height: (sous_element.valeur * parseFloat(pixelsParUnite)) + 'px',
          backgroundColor: 'rgba(255,255,255,'+(1-1/(index_sous_element+1))+')'
        }"
      >
      </div>

    </div>

    <div
      class="detail_texte"
      :class="{ petit: element.valeur * parseFloat(pixelsParUnite) < 40 }"
    >
      <span
        v-for="(sous_element) in element.sous_elements"
        v-bind:key="sous_element.libelle"
        class="sous_element"
        
        :style="{
          flexGrow: (sous_element.valeur)
        }"
      >
        {{sous_element.libelle}}
        <span class="valeur_detail">{{arrondir(sous_element.valeur,2)}} {{unite}}</span>
      </span>
      
    </div>


  </div>
</div>
</template>


<script>
export default {
  name: 'diagramme-en-barres',
  props: ['elements','pixelsParUnite','unite','total','uniteSecondaire','afficherUniteSecondaire'],
  emits: []
  ,
  data() {
    return {
      afficher_le_detail: false
    }
  },
  mounted(){
  },
  watch: {

  },
  methods: {
    arrondir(nombre,precision){
      if(Math.abs(nombre)<0.005 && nombre != 0){
        return Number(nombre).toFixed(3).toString().replace('.',',')
      }
      return Number(nombre).toFixed(precision).toString().replace('.',',')
    },
    basculer_affichage(){
      this.afficher_le_detail = !this.afficher_le_detail
    }
  }
}
</script>
