<style scoped>
.parametre-utilisateur{
  /* min-height: 2rem; */
  margin-bottom: 1.5rem;
}

.conteneur_champ{
  min-height: 2rem;
}
.champ{
  float: right;
}
.pourcentage .champ{
  float: none;
}
.glissiere{
  float: right;
}
.glissiere span{
  min-width: 2rem;
  /* ne fonctionne pas */
}
.libelle_champ{
  font-weight: 600;
  max-width: calc(100% - 5rem);
  display: inline-block;
  color: #414141;
}
.pourcentage .libelle_champ{
  max-width: calc(100% - 10rem);
}
.booleen .libelle_champ{
  max-width: calc(100% - 7rem);
}
.legende{
  max-width: calc(100% - 5rem);
  font-style: italic;
  color: #474747;
}
.champ_vertical{
  float: none;
  margin-top: 7px;
}
input[type="number"]{
  width: 4rem;
}
.radio_booleen{
  float: right;
}
.important .case_a_cocher{
  padding: 10px 12px;
  display: inline-block;
  border-radius: 6px;
  position: relative;
  left: -12px;
}
.important .activee{
  background: rgb(2 117 255 / 20%);
}
label{
  user-select:none;
}
.aide{
  background-color: #d7d7d7;
  border-radius: 10px;
  display: inline-block;
  width: 17px;
  height: 17px;
  text-align: center;
  vertical-align: middle;
  color: grey;
  font-weight: 600;
  font-size: 14px;
  position: relative;
  left: 4px;
  top: -2px;
  user-select: none;
  cursor: default;
}
</style>


<template>
  <div class="parametre-utilisateur" :class="{
    booleen: type_donnee =='booleen',
    pourcentage: type_donnee =='pourcentage',
    important: important=='important'
  }">

    <!-- les champs avant le texte pour tous ceux qui flottent a droite -->

    <!-- nombre -->
    <input
      v-if="type_donnee == 'nombre'"
      type="number"
      class="champ"
      :id="'input_'+parametre"
      :name="'input_'+parametre"
      :step="increment"
      v-model="valeurInterne"
    >

    <!-- pourcentage -->
    <div v-if="type_donnee == 'pourcentage'" class="glissiere">
      <input
        type="range"
        min="0.0"
        max="1.0"
        class="champ"
        :id="'input_'+parametre"
        :name="'input_'+parametre"
        :step="increment"
        v-model="valeurInterne"
      >
      <span v-if="type_donnee == 'pourcentage'">{{Math.round(valeurInterne * 100)}}%</span>
    </div>


    <!-- ouix/non -->
    <span
      class="radio_booleen"
      v-if="type_donnee == 'booleen'"
    >
      <!-- <option value="true" >oui</option>
      <option value="false" >non</option> -->
      <input type="radio"
        :id="'input_'+parametre+'_oui'"
        :name="'input_'+parametre"
        :value="true"
        v-model="valeurInterne">
      <label :for="'input_'+parametre+'_oui'">oui</label>
      <input type="radio"
        :id="'input_'+parametre+'_non'"
        :name="'input_'+parametre"
        :value="false"
        v-model="valeurInterne">
      <label :for="'input_'+parametre+'_non'">non</label>
      <br><br>
    </span>

    <!-- case a cocher -->
    <span
      class="case_a_cocher"
      :class="{activee : valeurInterne == true}"
      v-if="type_donnee == 'case a cocher'"
    >
      <!-- <option value="true" >oui</option>
      <option value="false" >non</option> -->
      <input type="checkbox"
        :id="'input_'+parametre"
        :name="'input_'+parametre"
        v-model="valeurInterne">
      <label :for="'input_'+parametre">{{libelle}}</label>
    </span>



    <span class="libelle_champ" v-if="type_donnee != 'case a cocher'">
      {{libelle}}
      <!-- <span class="asterisque" v-if="!is_nullable">*</span> -->
    </span>
    <span
      v-if="influence"
      class="aide"
      :aria-label="influence"
      role="tooltip"
      data-microtip-size="large"
    >?</span>
    <br>
    <span class="legende gris">{{legende}}</span>
    <!-- <br v-if="legende"> -->




    <!-- choix multiples -->
    <div
      v-if="type_donnee == 'choix_multiples'"
      class="champ champ_vertical"
    >
      <span
        v-for="option in options"
        :key="option"
        :value="option"
      >
        <input type="radio"
          :id="'input_'+parametre+'_'+option"
          :name="'input_'+parametre"
          :value="option"
          v-model="valeurInterne"
        >
        <label :for="'input_'+parametre+'_'+option">{{option}}</label>
        <br>
      </span>
      
    </div>

    


 <!-- { "libelle" : "", "valeur": ""}, -->

  </div>
</template>

<script>
export default {
  name: 'parametre-utilisateur',
  props: ['valeur','libelle','legende','influence','parametre','type_donnee','options','increment','important'],
  emits: ['input']
  ,
  data() {
    return {
      valeurInterne: undefined
    }
  },
  mounted(){

    this.valeurInterne = this.valeur
  },
  watch: {
    valeurInterne(nouvelle) {
      console.log("Valeur interne : ",nouvelle,this.type_donnee)

      if(this.type_donnee == 'pourcentage') {

        this.$emit('input', parseFloat(nouvelle))
        
      }
      else {
        this.$emit('input', nouvelle)
      }
    }
  },
  methods: {

  }
}
</script>
