
<style>
#deux_colonnes{
  display: flex;
}
.colonne{
  flex: 50%;
  padding: 1rem 2rem 3rem;

}
#colonne_parametres{
    background-color: #f2f2f2;
    box-sizing: border-box;
    flex: 35%;
}
#parametres_restaurant_satellite{
  margin-left: 2rem;
}
#cout-societaux{
  background-color: #00c2ce;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  text-align: center;
  margin: 1rem auto;
  border: none;
  color: white;
  font-weight: 600;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
}


#colonne_resultats{
  padding-top: 0;
}
#decoupe_de_couts{

}
.coller_en_haut{
  position: fixed;
  top: 0;
  /* max-height: 100%;
  overflow: auto; */
}
.ne_pas_coller{
  position: inherit;

}
#parametres_affichage{
  color: grey;
  margin-top: 1rem;
  background-color: #f2f2f2;
  padding: 1rem;
}


table{
  /* margin: auto; */
  text-align: left;
}
td{
  padding-right: 1rem;
}

.encart{
    padding: 1rem 1rem 0 1rem;
    background-color: rgb(0 194 206 / 11%);
    margin-bottom: 1rem;
    border-radius: 18px;
    /* text-align: justify; */
    margin-top: 2rem;
}
.encart h3{
  margin-top: 0rem;
}

</style>


<template>
  <div>
    
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div id="deux_colonnes">
    <div id="colonne_parametres" class="colonne">
    
    <h3>Paramètres d'affichage</h3>
    <div>
      <input type="checkbox" v-model="cacher_externalites" id="cacher_externalites" />
      <label for="cacher_externalites">Afficher le coût comptable seulement (hors externalités)</label>
      <br>
      <input type="checkbox" v-model="afficher_la_transition" id="afficher_la_transition" />
      <label for="afficher_la_transition">Afficher le coût de la transition</label>
    </div>

    
    <h3>Principales caractéristiques du restaurant scolaire</h3>

    <input type="checkbox" v-model="mode_expert" id="choix_mode_expert" />
    <label for="choix_mode_expert">Mode expert</label>

    <div v-if="mode_expert" id="mode_expert">

      <div
        v-for="poste in ['Fabrication','Encadrement','Administration']"
        :key="poste"
      >
        <h3>{{ poste }}</h3>
      
        <div
          v-for="champ in champs"
          :key="champ.parametre"
          class="conteneur_champ"
        >
          <ParametreUtilisateur
            v-if="champ.categorie == 'mode expert '+poste.toLowerCase()"
            :parametre="champ.parametre"
            :libelle="champ.libelle"
            :legende="champ.legende"
            :influence="champ.influence"
            :type_donnee="champ.type_donnee"
            :valeur="champ.valeur"
            :options="champ.options"
            :increment="champ.increment"
            v-on:input="modifier_parametre($event,champ.parametre)"
          />
        </div>
      
      </div>
      
      
    </div>

    <div v-else id="mode_facile">

      <h3>Nombre de repas</h3>

      <div
        v-for="champ in champs"
        :key="champ.parametre"
        class="conteneur_champ"
      >
        <ParametreUtilisateur
          v-if="champ.categorie == 'nombre de repas'"
          :parametre="champ.parametre"
          :libelle="champ.libelle"
          :legende="champ.legende"
          :influence="champ.influence"
          :type_donnee="champ.type_donnee"
          :valeur="champ.valeur"
          :options="champ.options"
          :increment="champ.increment"
          v-on:input="modifier_parametre($event,champ.parametre)"
        />
      </div>


    <!-- Fin du mode facile -->
    </div>

    <h3>Type de structure</h3>

    <ParametreUtilisateur
      :parametre="champs.type_de_cantine.parametre"
      :libelle="champs.type_de_cantine.libelle"
      :legende="champs.type_de_cantine.legende"
      :influence="champs.type_de_cantine.influence"
      :type_donnee="champs.type_de_cantine.type_donnee"
      :valeur="champs.type_de_cantine.valeur"
      :options="champs.type_de_cantine.options"
      :increment="champs.type_de_cantine.increment"
      v-on:input="modifier_parametre($event,champs.type_de_cantine.parametre)"
    />
    <div id="parametres_restaurant_satellite" v-if="champs.type_de_cantine.valeur == 'restaurant satellite'">
      <ParametreUtilisateur
        :parametre="champs.rattachement_cantine_satellite.parametre"
        :libelle="champs.rattachement_cantine_satellite.libelle"
        :legende="champs.rattachement_cantine_satellite.legende"
        :influence="champs.rattachement_cantine_satellite.influence"
        :type_donnee="champs.rattachement_cantine_satellite.type_donnee"
        :valeur="champs.rattachement_cantine_satellite.valeur"
        :options="champs.rattachement_cantine_satellite.options"
        :increment="champs.rattachement_cantine_satellite.increment"
        v-on:input="modifier_parametre($event,champs.rattachement_cantine_satellite.parametre)"
      />
      <ParametreUtilisateur
        :parametre="champs.repas_par_jour_cantine_centrale_rattachee.parametre"
        :libelle="champs.repas_par_jour_cantine_centrale_rattachee.libelle"
        :legende="champs.repas_par_jour_cantine_centrale_rattachee.legende"
        :influence="champs.repas_par_jour_cantine_centrale_rattachee.influence"
        :type_donnee="champs.repas_par_jour_cantine_centrale_rattachee.type_donnee"
        :valeur="champs.repas_par_jour_cantine_centrale_rattachee.valeur"
        :options="champs.repas_par_jour_cantine_centrale_rattachee.options"
        :increment="champs.repas_par_jour_cantine_centrale_rattachee.increment"
        v-on:input="modifier_parametre($event,champs.repas_par_jour_cantine_centrale_rattachee.parametre)"
      />

      <ParametreUtilisateur
        important="important"
        :parametre="champs.afficher_cantine_centrale.parametre"
        :libelle="champs.afficher_cantine_centrale.libelle"
        :legende="champs.afficher_cantine_centrale.legende"
        :influence="champs.afficher_cantine_centrale.influence"
        :type_donnee="champs.afficher_cantine_centrale.type_donnee"
        :valeur="champs.afficher_cantine_centrale.valeur"
        :options="champs.afficher_cantine_centrale.options"
        :increment="champs.afficher_cantine_centrale.increment"
        v-on:input="modifier_parametre($event,champs.afficher_cantine_centrale.parametre)"
      />


    </div>

    <h3>Leviers de transition</h3>

    <div
      v-for="champ in champs"
      :key="champ.parametre"
      class="conteneur_champ"
    >
      <ParametreUtilisateur
        v-if="champ.categorie == 'leviers'"
        :parametre="champ.parametre"
        :libelle="champ.libelle"
        :legende="champ.legende"
        :influence="champ.influence"
        :type_donnee="champ.type_donnee"
        :valeur="champ.valeur"
        :options="champ.options"
        :increment="champ.increment"
        v-on:input="modifier_parametre($event,champ.parametre)"
      />
    </div>
    <p><b>Part de produits locaux:</b> non modélisé</p>
    <p><b>Part de produits durables (hors bio):</b> non modélisé<br></p>

    <div class="encart">
      <h3>Impacts des leviers de transition</h3>
      <p>
        Au vu des paramètres choisis, l'impact carbone du repas moyen du restaurant scolaire modélisé est de <b>{{ valeur(donnees.find(c=> c.etape=='sociétal' && c.poste=='climat' && c.indicateur=='empreinte carbone par repas' && c.levier=='final' ),2) }} kgCO2e</b>, soit <b>{{ valeur(donnees.find(c=> c.etape=='sociétal' && c.poste=='climat' && c.indicateur=='cout carbone par repas' && c.levier=='final' ),2) }} euros</b>. <br>
        Il faut <b>{{ valeur(donnees.find(c=> c.etape=='sociétal' && c.poste=='sols' && c.indicateur=='m2' && c.levier=='final' ),0) }} m² par convive</b> pour produire les denrées de l'ensemble des repas de l'année scolaire.<br>
        A titre d'exemple des coûts évités calculés, le passage en bio permet d'éviter <b>{{ valeur(donnees.find(c=> c.etape=='sociétal' && c.poste=='eau' && c.indicateur=='économie dépollution' && c.levier=='bio' ),2) }} euros de coût de dépollution de l'eau</b>.<br>
        <br>
        Pour plus d'informations sur les coûts cachés et évités de la transtion et leurs impacts sur la durabilité, cliquez sur le bouton ci-dessous:
        <br>
      <button
          id="cout-societaux"
          @click="this.$emit('navigation', 'couts-societaux')"
      >Afficher les coûts cachés et évités</button>
      </p>
    </div>
    <h3>Plus de paramètres</h3>

    <div
      v-for="champ in champs"
      :key="champ.parametre"
      class="conteneur_champ"
    >
      <ParametreUtilisateur
        v-if="champ.categorie == 'parametres supplementaires'"
        :parametre="champ.parametre"
        :libelle="champ.libelle"
        :legende="champ.legende"
        :type_donnee="champ.type_donnee"
        :valeur="champ.valeur"
        :options="champ.options"
        :increment="champ.increment"
        v-on:input="modifier_parametre($event,champ.parametre)"
      />
    </div>



    <button @click="reinitialiser_parametres()">Réinitialiser les paramètres</button>


    <h3>Autres paramètres d'affichage</h3>
    <!-- <div id="parametres_affichage"> -->
    <ParametreUtilisateur
      :parametre="champs.cout_total_ou_par_repas.parametre"
      :libelle="champs.cout_total_ou_par_repas.libelle"
      :legende="champs.cout_total_ou_par_repas.legende"
      :type_donnee="champs.cout_total_ou_par_repas.type_donnee"
      :valeur="champs.cout_total_ou_par_repas.valeur"
      :options="champs.cout_total_ou_par_repas.options"
      :increment="champs.cout_total_ou_par_repas.increment"
      v-on:input="modifier_parametre($event,champs.cout_total_ou_par_repas.parametre)"
    />
    <div>
      <input type="checkbox" v-model="cacher_investissements" id="cacher_investissements" />
      <label for="cacher_investissements">Ne pas afficher l’investissement</label>
      <br>
      <input type="checkbox" v-model="cacher_encadrement" id="cacher_encadrement" />
      <label for="cacher_encadrement">Ne pas afficher l'encadrement</label>
      <br>
    </div>

    <!-- </div> -->
    </div>
    
    <div id="colonne_resultats" class="colonne">
    <!-- <p>{{donnees}}</p> -->

    <div
      id="decoupe_de_couts"
      :class="{coller_en_haut: coller_en_haut, ne_pas_coller: !coller_en_haut}"
    >
    
      <DiagrammeEnBarres
        v-if="champs.cout_total_ou_par_repas.valeur == 'par repas'"
        :elements="etapes.map(c=>{
          return {
            libelle:  c.etape,
            valeur:   c.cout_final,
            detail_calcul_valeur: c.cout_final_detail,
            valeur_secondaire:   c.cout_transition,
            detail_calcul_valeur_secondaire: formatter_detail_cout_transition(c.etape),
            sous_elements: c.elements.map(e=>{return {libelle:e.poste,valeur:e.cout_final}}).sort((a,b)=>b.valeur-a.valeur),
            couleur:  this.couleurs[c.etape]
          }
        }).filter(e=>e.valeur > 0)"
        pixels-par-unite="60"
        unite="€"
        unite-secondaire="€"
        :afficherUniteSecondaire="afficher_la_transition"
        :total="cout"
        chiffres-significatifs="3"
      />
      <DiagrammeEnBarres
        v-if="champs.cout_total_ou_par_repas.valeur == 'total annuel'"
        :elements="etapes.map(c=>{
          return {
            libelle:  c.etape,
            valeur:   c.cout,
            valeur_secondaire:   c.transition,
            sous_elements: c.elements.map(e=>{return {libelle:e.poste,valeur:e.cout}}).sort((a,b)=>b.valeur-a.valeur),
            couleur:  this.couleurs[c.etape]
          }
        }).filter(e=>e.valeur > 0)"
        :pixels-par-unite="400/cout"
        unite="€"
        :total="cout"
        chiffres-significatifs="3"
      />




    </div>


    </div>
    </div>
  </div>
</template>

<script>
import champs from '../json/champs.json'
import ParametreUtilisateur from './parametre-utilisateur.vue'
import DiagrammeEnBarres from './diagramme-en-barres.vue'
import basicApi from '@/repositories/basic_api.js'


// const routes = [
//   { path: '', component: Publications },
//   { path: '/publications', component: Publications },
//   { path: '/publication', component: Publications },

// ]

// const router = new VueRouter({
//   routes // short for `routes: routes`
// })


export default {
  name: 'DecoupeDeCouts',
  components: {
    ParametreUtilisateur,
    DiagrammeEnBarres
  },
  data(){
    var nos_champs = {}
    var sauvegarde = JSON.parse(localStorage.getItem('parametres')) || {}
    console.log('Sauvegarde : ',sauvegarde)
    champs.forEach(c=>{
      if(Object.prototype.hasOwnProperty.call(sauvegarde, c.parametre)) {
        c.valeur = sauvegarde[c.parametre].valeur || c.valeur_par_defaut
      } else {
        c.valeur = c.valeur_par_defaut
      }
      nos_champs[c.parametre] = c
      // return c
    })
    console.log('this.champs',nos_champs)
    return {
      champs: nos_champs,
      donnees: [],
      etapes: [],
      postes: [],
      cout: 0,
      bidon: 13,
      coller_en_haut: false,
      hauteur_sous_le_plafond: 0,
      cacher_investissements: false,
      cacher_encadrement: false,
      cacher_externalites: false,
      afficher_la_transition: true,
      afficher_cantine_centrale: false,
      mode_expert: false,
      couleurs : {
        'administratif':  '#51dee6',
        'fabrication':    '#ffaad7',
        'assemblage':     '#ffeb8d',
        'distribution':   '#5ce7bf',
        'encadrement des élèves':    '#ffc973',
        'livraison':      'lightgrey',
        'marge':          '#efefef',
        'externalités':   '#53C79F',
      },
      etapes_possibles: ['administratif','fabrication','assemblage','distribution','encadrement des élèves','livraison','marge','externalités']
    }
  },
  mounted(){
    this.restaurer_parametres()
    this.recuperer_la_decoupe_de_valeur()

    window.addEventListener('scroll', this.gererLeDefilement);
    
  },
  watch: {
    champs : {
      handler() {
        this.recuperer_la_decoupe_de_valeur()
        this.sauvegarder_parametres()
      },
      deep: true
    },
    cacher_investissements() {
      this.aggreger_les_donnees()
    },
    cacher_encadrement() {
      this.aggreger_les_donnees()
    },
    cacher_externalites() {
      this.aggreger_les_donnees()
    }
  },
  methods:{

    grouper_par_clef: function(donnees,clef){
      var retour = {}
      donnees.forEach(d=>{

        d[d.indicateur + '_' + d.levier] = d.valeur

        if( ! retour[d[clef]] ){

          // initialisation du groupe
          retour[d[clef]] = {elements: []}
          retour[d[clef]][clef] = d[clef]

          retour[d[clef]][d.indicateur + '_' + d.levier] = d.valeur
          retour[d[clef]][d.indicateur + '_' + d.levier + '_detail'] = this.arrondir(d.valeur,2) + '€ de ' + d.poste

        } else {
          retour[d[clef]][d.indicateur + '_' + d.levier] = retour[d[clef]][d.indicateur + '_' + d.levier]
            ? retour[d[clef]][d.indicateur + '_' + d.levier] + d.valeur
            : d.valeur
          // le detail du calcul
          retour[d[clef]][d.indicateur + '_' + d.levier + '_detail'] = retour[d[clef]][d.indicateur + '_' + d.levier + '_detail']
            ? retour[d[clef]][d.indicateur + '_' + d.levier + '_detail'] + ' + ' + this.arrondir(d.valeur,2) + '€ de ' + d.poste //eslint-disable-line
            : this.arrondir(d.valeur,2) + '€ de ' + d.poste
        }

        if(d.indicateur + '_' + d.levier == 'cout_final') {
          retour[d[clef]].elements.push(d)
        }
      })
      // donnees.forEach(d=>{
      //   retour[d[clef]].elements
      //   if( d.indicateur + '_' + d.levier == 'cout_transition'){
      //     var sous_element = retour[d[clef]].elements.find(e => e.etape == d.etape && e.poste == d.poste)
          
      //     sous_element.cout_transition = d.valeur
      //   }
      // })
      console.log('Groupé par ',clef,retour)
      return Object.values(retour)

    },
    recuperer_la_decoupe_de_valeur: function(){
      var parametres = Object.fromEntries(Object.values(this.champs).filter(c=>c.valeur != undefined || c.valeur !== null).map(c=>[c.parametre,c.valeur]))
      console.log('Paramètres :',parametres)
      basicApi.recuperer_la_decoupe_de_valeur(parametres)
      .then(res => {
        this.donnees = res.data
        console.log('Données récupérées',res)
        if(this.donnees.length == 0){
          return false
        }
        // console.log('Liste des couts de transition',this.donnees.filter(e=>{ return e.indicateur == 'cout' && ['final','transition'].indexOf(e.levier) == -1  }))
        this.aggreger_les_donnees()
        // console.log('Etapes :',this.etapes)
        this.postes = this.grouper_par_clef(this.donnees,'poste')
        // console.log('Etapes :',this.etapes)

      })
    },
    aggreger_les_donnees(){
      this.etapes = this.grouper_par_clef(this.donnees.filter(e=>{
        return ( e.poste != 'investissement' || this.cacher_investissements == false )
            && ( e.etape != 'encadrement des élèves' || this.cacher_encadrement == false )
            && ( e.etape != 'externalités' || this.cacher_externalites == false )
            && ( this.etapes_possibles.indexOf(e.etape) > -1 )
            && ( !( this.afficher_cantine_centrale && e.etape == 'externalités' ) )
            && ['cout','etp'].indexOf(e.indicateur) > -1
            && ['final','transition'].indexOf(e.levier) > -1
            && (  e.poste != 'sols' || e.indicateur != 'cout' ) // on exclue les postes non monétarisables (meme s'ils sont a zero)
            && (  e.poste != 'biodiversité' || e.indicateur != 'cout' )
            && (  e.poste != 'niveau de vie décent' || e.indicateur != 'cout' )
            // && e.valeur > 0
      }),'etape').sort(
        (a,b) => {
          return this.etapes_possibles.indexOf(a.etape) - this.etapes_possibles.indexOf(b.etape)
        })


      // trier les etapes dans l'ordre des etapes possibles
      // this.etapes = this.etapes
      this.cout = 0
      this.donnees.filter(e=>{
        return ( e.poste != 'investissement' || this.cacher_investissements == false )
            && ( e.etape != 'encadrement des élèves' || this.cacher_encadrement == false )
            && ( e.etape != 'externalités' || this.cacher_externalites == false )
            && ( e.etape != 'externalités' || this.afficher_cantine_centrale == false )
            && this.etapes_possibles.indexOf(e.etape) > -1
            && ['cout'].indexOf(e.indicateur) > -1
            && ['final'].indexOf(e.levier) > -1
      }).forEach(d=>{this.cout+=d.valeur})

    },
    sauvegarder_parametres: function(){
      // var parametres = Object.fromEntries(Object.values(this.champs).map(c=>[c.parametre,c.valeur]))
      console.log('sauvegarde des champs',JSON.stringify(this.champs))
      localStorage.setItem('parametres', JSON.stringify(this.champs));
    },
    restaurer_parametres: function(){
      // var parametres = JSON.parse(localStorage.getItem('parametres'))
      // // console.log('parametres',parametres)
      // Object.values(this.champs).forEach(c => {
      //   this.champs[c.parametre].valeur = parametres[c.parametre].valeur
      //   // if(parametres[c.parametre]) c.valeur = parametres[c.parametre]
      // })
    },
    reinitialiser_parametres: function(){
      localStorage.clear();
      location.reload();
    },
    modifier_parametre(e,parametre){
      console.log('modifier_parametre',e)
      this.champs[parametre].valeur = e
    },
    gererLeDefilement(){
      var haut = document.getElementById('decoupe_de_couts').offsetTop
      this.hauteur_sous_le_plafond = haut > 0 ? haut : this.hauteur_sous_le_plafond

      var y = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

      this.coller_en_haut = y >= this.hauteur_sous_le_plafond
    },
    valeur(chiffre,arrondi){
      arrondi = arrondi || 0
      if(chiffre != undefined){
        var retour = Math.round(chiffre.valeur * Math.pow(10,arrondi)) / Math.pow(10,arrondi)
        console.log('arrondi',chiffre.valeur,retour,arrondi)
        return retour
      }
      return ''
    },
    arrondir(nombre,precision){
      if(Math.abs(nombre)<0.005 && nombre != 0){
        return Number(nombre).toFixed(3).toString().replace('.',',')
      }
      return Number(nombre).toFixed(precision).toString().replace('.',',')
    },
    formatter_detail_cout_transition(etape){
      // console.log('detail des couts de transition pour ',etape)
      var couts = this.donnees.filter(e=>{
        return  e.indicateur == 'cout'
                && ['final','transition','initial'].indexOf(e.levier) == -1
                && e.valeur != 0
                && e.etape == etape
      }).map(e=>{
        return (e.valeur>0?'+':'') + this.arrondir(e.valeur,2) + '€ de ' + e.levier
      })
      // console.log('detail des couts de transition',couts)
      return couts.join('  ')

    }
  }
}
</script>
