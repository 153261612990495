import axios from "axios";

var api_url = "https://restauration-scolaire.azurewebsites.net/api/decoupe-de-couts?code=W-Ltai3YeW2XfP_PDd2Cqm2FqvJFMVCF8TVmSg0sbLCjAzFujDYF6w==" 
// api_url = "https://observatoire-filieres.azurewebsites.net/restauration_scolaire/"
api_url = "https://lebasic.nohost.me/api/restauration_scolaire/"
const apiClient = axios.create({
    baseURL: api_url,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

export default {
  async recuperer_la_decoupe_de_valeur(parametres) {
    return await apiClient.post("", parametres)
  }
};
