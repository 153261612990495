<style scoped>
#conteneur{
  background-color: inherit;
  display: flex;
}
#retour{
  cursor: pointer;
}
h2 {
  margin-top: 1rem;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  opacity: 0.5;
}
h3 {
  margin-top: 1rem;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  opacity: 0.5;
}
h4 {
  margin-top: 1rem;
  font-weight: normal;
  font-size: 1rem;
  /* text-transform: uppercase; */
  /* opacity: 0.5; */
  text-decoration: underline;
  margin-bottom: -0.5rem;
}
b{
  font-weight: 600;
}
#doughnut{
  height: 550px;
  width: 550px;
  position: relative;
  /* border: 1px solid red; */
}
#cercle-social{

  height: 490px;
  width: 490px;
  border-radius: 100%;
  border: 16px solid #D6F1E5;
  position: absolute;
  top: 41px;
  left: 41px;
}
#cercle-environnemental{

  height: 295px;
  width: 295px;
  border-radius: 100%;
  border: 16px solid #D2EAF7;
  position: absolute;
  top: 139px;
  left: 139px;
}
#details{
  max-width: calc(100% - 660px);
  background-color: #f2f2f2;
  background: linear-gradient(119.25deg, #F7F7F7 0%, rgba(217, 217, 217, 0) 40.59%);
  border-radius: 10px 0 0 0;
  margin-left: 5rem;
  padding: 0 1rem;
  box-shadow: rgb(0 0 0 / 16%) -2px 2px 3px 0px;
}
.lumiere {
  position: absolute;
}

.cercle{
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #1E98D5;
  color: #1E98D5;
  /* font-size: 14px; */
  font-weight: 600;

  position: relative;
  top: -30px;
  left: -30px;
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  /* transition: 0.3s; */

}
.cercle:hover{
  /* opacity: 0.8; */
  outline: 10px solid #f0f0f0;
}
.vert .cercle {
  background-color: #33BB7D;
  color: #33BB7D;
}

.desactive {
  /* filter: brightness(0.1) invert(0.9); */
  /* opacity: 0.3; */
  background-color: #ceebf9;
  outline: none;
}
.vert .desactive{
  background-color: #e7f2d5;

}
.active {
  /* outline: 3px solid #E8B52C;
  outline-offset: 3px; */

}
.active:hover{
  filter: saturate(1.5) brightness(0.9);
}

.libelle{
  position: absolute;
  top: calc(100% + 5px);
  text-align: center;
  width: 200%;
  left: -50%;
  /* background-color: rgba(240, 240, 240,0.2); */
  border-radius: 6px;
  line-height: 1rem;
  letter-spacing: -0.5px;
}


.collectivite {
  background-color: #fbe4a8;
  border-radius: 5px;
  padding: 3px;
  margin: 3px 0;
  position: relative;
}

.negatif{
  background-color: rgb(255, 231, 231);
  border-radius: 3px;
  padding: 0 3px;
  color: rgb(101, 0, 0);

}
.positif{
  background-color: rgb(210, 241, 210);
  border-radius: 3px;
  padding: 0 3px;
  color: rgb(6, 67, 6);

}
</style>


<template>

  
  <div class="container">
  <a
    id="retour"
    @click="this.$emit('navigation', 'decoupe-de-couts')"
  >← Retour</a>
  <div id="conteneur">
    <div id="doughnut">

    <div id="cercle-social"></div>
    <div id="cercle-environnemental"></div>
    
    <div
      v-for="problematique in problematiques"
      v-bind:key="problematique.libelle_problematique"
    >
      <div
        v-bind:class="{
          lumiere : true,
          vert: problematique.categorie_problematique == 'Social' ? false : true
        }"
        v-bind:style="{
          left : 'calc('+centre+' + '+rayon+'*'+ cos(
              2*pi*(problematique.numero-1)/( problematique.categorie_problematique == 'Social' ? 8 : 7 )
              - pi/2
              - problematique.decalage
            )*problematique.multiplicateur_rayon
            +')',
          top :  'calc('+centre+' + '+rayon+'*'+ sin(
              2*pi*(problematique.numero-1)/( problematique.categorie_problematique == 'Social' ? 8 : 7 )
              - pi/2
              - problematique.decalage
            )*problematique.multiplicateur_rayon
            +')' 
        }"
      >
        <div
          v-bind:class="{
            cercle: true
          }"
          v-bind:style="{
            backgroundImage: problematique.image
          }"
          v-on:click="selectionner(problematique)"
        >
          <div class="libelle" v-bind:title="JSON.stringify(problematique.enjeux)">
            {{problematique.libelle_problematique_positif}}
          </div>
        </div>
        <!-- {{problematique.libelle_problematique_positif}} -->
      </div>
    </div>

    </div>

  <div id="details" v-if="problematique_selectionnee.libelle_problematique_positif">
    <h2>{{problematique_selectionnee.libelle_problematique_positif}}</h2>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'CLIMAT'">

      <h3>État des lieux</h3>
      <p>
        L'impact carbone moyen d’un repas en restauration scolaire est de <b>2,43 kgCO2e  soit 0,22 €</b>
      </p>
      <h3>Impact des leviers de transition</h3>
      Au vu des leviers de transition actionnés précédemment, l'impact carbone moyen d'un repas de votre restauration scolaire est de <b class="">{{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='climat' && c.indicateur=='empreinte carbone par repas' && c.levier=='final' ),2) }} kgCO2e</b>, soit <b>{{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='climat' && c.indicateur=='cout carbone par repas' && c.levier=='final' ),2) }} euros</b>
      <h4>Part de repas végétariens :</h4>
      <p>
        Compte tenu de la part de repas végétariens choisie dans les paramètres, les émissions évitées représentent  <b class="">{{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='climat' && c.indicateur=='économie empreinte carbone par repas' && c.levier=='vegetarien' ),2) }} kgCO2e</b>, soit <b class="">{{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='climat' && c.indicateur=='économie cout carbone par repas' && c.levier=='vegetarien' ),2) }} euros</b>
      </p>
      <h4>Réduction du gaspillage :</h4>
      <p>
        Compte tenu de la réduction du gaspillage choisie dans les paramètres, les émissions évitées représentent  <b class="">{{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='climat' && c.indicateur=='économie empreinte carbone par repas' && c.levier=='gaspillage' ),2) }} kgCO2e</b> soit <b class="">{{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='climat' && c.indicateur=='économie cout carbone par repas' && c.levier=='gaspillage' ),2) }} euros</b>
      </p>
      <h4>Part de produits bio :</h4>
      <p>L’impact de la bio sur le dérèglement climatique est ambigu:
      <ul>
        <li>la <b class="positif">moindre utilisation d’intrants chimiques</b>, dont la fabrication émet des gaz à effet de serre, induit une réduction des émissions</li>
        <li>la <b class="negatif">plus grande mécanisation</b> de la production induit une augmentation des émissions de gaz à effet de serre</li>
      </ul>
    </p>
      <h4>Part de produits locaux :</h4>
      <p>L’impact de l’approvisionnement en produits locaux n’est pas connu: les émissions évitées grâce au trajet plus court peuvent être compensées par l’impact du dernier km du fait d’une logisitique moins optimisée</p>
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'SOLS'">

      <h3>État des lieux</h3>
      <p>
        Pour alimenter un enfant en restauration scolaire sur l’année, il faut en moyenne <b class="negatif">630 m2</b>, soit 0,063 hectares
      </p>
      <h3>Impact des leviers de transition</h3>
      <p> Au vu des leviers de transition actionnés précédemment, la surface moyenne nécessaire par convive est de {{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='sols' && c.indicateur=='m2' && c.levier=='final' ),0) }} m²
      </p>
      <h4>Part de produits bio</h4>
      <p>
        Les produits issus de l’agriculture biologique nécessitent plus de terres agricoles. Ainsi par convive, il faut mobiliser <b class="negatif">{{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='sols' && c.indicateur=='m2 supplémentaires' && c.levier=='bio' ),0) }} m² en plus</b>.
        <br>
        Cependant, l’agriculture biologique a un impact positif sur les sols dans la mesure où elle permet de <b class="positif">réduire la pollution chimique des sols</b>. 
      </p>
      <h4>Part de repas végétariens</h4>
      <p>
        Les produits végétaux nécessitent moins de terres agricoles que les produits animaux. Ainsi par convive, il faut mobiliser <b class="positif">{{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='sols' && c.indicateur=='m2 en moins' && c.levier=='vegetarien' ),0) }} m² en moins</b>.
      </p>
      <h4>Réduction du gaspillage</h4>
      <p>
        La réduction du gaspillage permet de réduire le besoin en denrées alimentaires nécessaires. Ainsi par convive, il faut mobiliser <b class="positif">{{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='sols' && c.indicateur=='m2 en moins' && c.levier=='gaspillage' ),0) }} m² en moins</b>.
      </p>
      
    
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'RESSOURCE EN EAU'">

      <h3>Impact des leviers de transition</h3>
      <h4>Part de produits bio</h4>
      <p>
        Les produits issus de l’agriculture biologique participent à éviter la pollution de l’eau du fait d’une moindre utilisation d’intrants chimiques. Ainsi l’achat de produits bio permet d’<b class="positif">éviter {{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=="dépollution de l'eau" && c.indicateur=='économie dépollution' && c.levier=='bio' ),2) }} euros</b> de dépollution de l’eau.
      </p>
      <h4>Part de repas végétariens</h4>
      <p>
        Les productions végétales utilisent plus d’eau puisée que les productions animales (qui utilisent majoritairement de l’eau de pluie), ainsi <b class="negatif">la consommation d’eau (bleue) nécessaire pour la production des denrées brutes utilisées augmente</b>.
      </p>
      <h4>Interdiction du plastique</h4>
      <p>
        La moindre utilisation de plastiques permet de <b class="positif">réduire les déchets plastiques</b>, qui se retrouvent dans les océans.
      </p>
      <h4>Réduction du gaspillage</h4>
      <p>
        La réduction du gaspillage permet de réduire les quantités de denrées utilisées et donc <b class="positif">les quantités d'eau utilisées lors de la préparation du repas</b>. De plus, cela <b class="positif">réduit les quantités d'eau utilisées pour irriguer les cultures nécessaires</b> à la production de ces denrées.
      </p>
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'COHÉSION SOCIALE'">
      <h3>État des lieux</h3>
      <p>
        La restauration scolaire étudiée permet de <b class="positif">créer des emplois locaux</b>, qui sont détaillés selon les postes suivants (exprimés en équivalent temps plein):
      </p>
      <ul>
        <li>Administratif : {{ valeur(chiffres.find(c=> c.etape=='administratif' && c.poste=='personnel' && c.indicateur=='etp' && c.levier=='final' ),1) }} ETP</li>
        <li>Fabrication / Assemblage : {{ valeur(chiffres.find(c=> c.etape=='fabrication' && c.poste=='personnel' && c.indicateur=='etp' && c.levier=='final' ),1) }}{{ valeur(chiffres.find(c=> c.etape=='assemblage' && c.poste=='personnel' && c.indicateur=='etp' && c.levier=='final' ),1) }} ETP</li>
        <li>Distribution : {{ valeur(chiffres.find(c=> c.etape=='distribution' && c.poste=='personnel' && c.indicateur=='etp' && c.levier=='final' ),1) }} ETP</li>
        <li>Encadrement des élèves : {{ valeur(chiffres.find(c=> c.etape=='encadrement' && c.poste=='personnel' && c.indicateur=='etp' && c.levier=='final' ),1) }} ETP</li>
      </ul>
      <h3>Impact des leviers de transition</h3>
      <h4>Part de produits locaux</h4>
      <p>
        L’achat de produits locaux <b class="positif">crée de la valeur économique pour le territoire</b>.
      </p>
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'NIVEAU DE VIE DÉCENT'">
      <h3>État des lieux</h3>
      <p>
        Les revenus moyens par fonction sont les suivants (salaire super-brut):
      </p>
      <ul>
        <li>Administratif : {{ valeur(chiffres.find(c=> c.etape=='administratif' && c.poste=='personnel' && c.indicateur=='salaire super-brut' && c.levier=='initial' ),0) }} euros par an</li>
        <li>Fabrication : {{ valeur(chiffres.find(c=> c.etape=='fabrication' && c.poste=='personnel' && c.indicateur=='salaire super-brut' && c.levier=='initial' ),0) }} euros par an</li>
        <li>Livraison : {{ valeur(chiffres.find(c=> c.etape=='livraison' && c.poste=='personnel' && c.indicateur=='salaire super-brut' && c.levier=='initial' ),0) }}{{ valeur(chiffres.find(c=> c.etape=='assemblage' && c.poste=='personnel' && c.indicateur=='salaire super-brut' && c.levier=='initial' ),0) }} euros par an</li>
        <li>Distribution : {{ valeur(chiffres.find(c=> c.etape=='distribution' && c.poste=='personnel' && c.indicateur=='salaire super-brut' && c.levier=='initial' ),0) }} euros par an</li>
        <li>Encadrement des élèves : {{ valeur(chiffres.find(c=> c.etape=='encadrement' && c.poste=='personnel' && c.indicateur=='salaire super-brut' && c.levier=='initial' ),0) }} euros par an</li>
      </ul>
      <p>
        Il s’agit de revenus annuels en estimant le temps de travail annuel dédié à la restauration scolaire lorsqu’il ne s’agit pas de temps plein.
        <br>
        Ainsi <b class="negatif">{{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='niveau de vie décent' && c.indicateur=='personnes en dessous du SMIC' && c.levier=='final' )) }} personnes sont en dessous du SMIC annuel</b> (sans considérer les autres activités ou emplois qu’elles peuvent avoir par ailleurs).
        <br>
        <!-- Pour que l’ensemble de ces personnes atteignent un SMIC annuel, elles doivent <b class="">compléter leurs revenus de {{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='niveau de vie décent' && c.indicateur=='euros pour atteindre le SMIC' && c.levier=='final' ),-3) }} euros</b> au total. -->
      </p>

      <h3>Impact des leviers de transition</h3>
      <h4>Interdiction du plastique:</h4>
      <p>
        Le remplacement du plastique par des contenants en verre entraîne un temps de travail plus important pour le nettoyage, la manutention, etc. Ainsi <b class="positif">lorsqu'il s'agit de personnes à temps partiel, cela peut participer à augmenter leur revenu</b>.
      </p>
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'SÉCURITÉ ALIMENTAIRE'">
      <h3>État des lieux</h3>
      <p>
        La restauration scolaire permet <b class="positif">aux convives les plus précaires d’avoir accès à une alimentation équilibrée</b>.<br>
        Pour avoir une estimation de convives en risque de précarité du fait de la tarification, voir le module “recettes”.
      </p>
      <h3>IMPACTS DES caractéristiques de la cantine</h3>
      <h4>Présence d’un.e diététicien.ne</h4>
      <p>
        Cela permet de <b class="positif">s’assurer que les menus sont équilibrés</b>, d’autant plus lorsque l’approvisionnement est modifié par les leviers de transition (notamment l’introduction des repas végétariens), et qu’il faut aider les cuisiniers à s’adapter.
      </p>
      <h3>Impact des leviers de transition</h3>
      <h4>Part de produits locaux</h4>
      <p>
        L’approvisionnement en produits locaux permet à la restauration scolaire de <b class="positif">soutenir les filières locales</b> et donc de renforcer l’autonomie alimentaire du territoire.
      </p>
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'CONDITIONS DE TRAVAIL'">
      <h3>État des lieux</h3>
      <p>
        Plusieurs maladies professionnelles sont reconnues en restauration scolaire :
        <ul>

          <li>du fait de travailler dans la restauration: risques de chute, de brûlures, coupures, douleurs au dos et aux articulations...</li>
          <li>du fait de travailler avec des enfants en bas âge: environnement bruyant, risques infectieux</li>
        </ul>
        En moyenne, <b class="negatif">20% des établissements sont en sous-effectif</b>, notamment dû à la difficulté de recruter du fait de la faible attractivité du métier.<br>
        Cela peut notamment engendrer des arrêts maladies souvent non remplacés (d’autant plus dans les petites structures), du fait d’une surchage de travail et de risques psycho-sociaux exacerbés. De plus, cette situation peut engendrer l’impossibilité de s’absenter pour suivre une formation sur les leviers de transition. 
      </p>
      <h3>IMPACTS DES caractéristiques de la cantine</h3>
      <h4>Cuisine sur place</h4>
      <p>
        Le lien entre cuisinier et convive est important dans la <b class="positif">valorisation du métier</b>.
      </p>
      <h4>Qualité sonore du refectoire</h4>
      <p>
        Cela permet à la fois aux enfants et aux adultes de passer le temps du repas dans un <b class="positif">environnement moins bruyant</b>.
      </p>
      <h3>Impact des leviers de transition</h3>
      <h4>Interdiction du plastique</h4>
      <p>
        Le remplacement de contenants en plastique par des contenants en verre notamment pose des problèmes de santé au travail du fait de leur poids beaucoup plus important. Ainsi, une augmentation des <b class="negatif">troubles musculosquelletiques</b> a été detectée.
      </p>
      <h4>Produits bruts</h4>
      <p>
        Le <b class="positif">métier de cuisinier est revalorisé</b> par le fait d’utiliser des produits bruts.
      </p>
      <h4>Ensemble des leviers de transition</h4>
      <p>
        La transition de la restauration scolaire nécessite un investissement financier mais également un investissement de temps. Elle dépend souvent de la <b class="negatif">motivation des équipes, qui peuvent travailler sur ces sujets bénévolement</b>, ce qui peut donc participer à dégrader les conditions de travail des salariés. Toutefois, il est possible d’éviter ce risque en accompagnant et valorisant ce travail.
      </p>
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'BIODIVERSITÉ'">
      <h3>État des lieux</h3>
      <p>
        Pour nourrir un enfant en restauration scolaire, <b class="">110 m² de surfaces importées sont nécessaires</b> en moyenne. Elles sont majoritairement situées dans des <b class="negatif">pays à risques de déforestation</b>.
      </p>
      <h3>Impact des leviers de transition</h3>
      <h4>Part de produits bio</h4>
      <p>
        Les produits issus de l’agriculture biologique participent à la <b class="positif">réduction des pressions sur la biodiversité</b> du fait d’une moindre utilisation d’intrants chimiques. De plus, l’élevage bio est un élevage extensif, herbager, qui permet le maintien de prairies, milieu riche en biodiversité.
      </p>
      <h4>Part de produits durables</h4>
      <p>
        Certains produits labellisés, selon les cahiers des charges, participent à la réduction des pressions sur la biodiversité (comme étudié dans <a href="https://bit.ly/durabilité-alimentaire" target="_blank">l’étude sur les labels alimentaires et leurs impacts sur la durabilité alimentaire</a>).
      </p>
      <h4>Part de repas végétariens</h4>
      <p>
        La réduction de produits animaux permet de réduire les surfaces importées de <b class="positif">{{ valeur(chiffres.find(c=> c.etape=='externalités' && c.poste=='biodiversité' && c.indicateur=='réduction m2 importés' && c.levier=='vegetarien' ),-1) }} m²</b>, et donc de réduire les risques de déforestation importée. En effet, une partie de l’alimentation animale est importée, notamment le soja brésilien.<br>
        Cependant les produits végétaux s’ils ne sont pas bio, sont des produits dont la production utilise plus de pesticides, sur des terres moins favorables à la biodiveristé.
      </p>
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'BIEN-ÊTRE ANIMAL'">
      
      <h3>Impact des leviers de transition</h3>
      <h4>Part de produits durables dont bio</h4>
      <p>
        Certains produits labellisés participent à <b class="positif">améliorer les conditions d’élevage et d’abattage des animaux</b>. C'est notamment le cas pour les produits issus de l'agriculture biologique. Pour plus d’informations, <a href="https://bit.ly/durabilité-alimentaire" target="_blank">l’étude sur les labels alimentaires et leurs impacts sur la durabilité alimentaire</a>
      </p>
      <h4>Part de repas végétariens</h4>
      <p>
        La réduction de la consommation de produits animaux participe à la <b class="positif">réduction du nombre d'animaux en situation d'élevage</b>.  
      </p>
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'QUALITÉ DE L\'AIR (HORS GES)'">

      <h3>Impact des leviers de transition</h3>
      <h4>Part de produits bio</h4>
      <p>
        Les produits issus de l’agriculture biologique participent à la réduction des pollutions de l’air du fait d’une <b class="positif">moindre utilisation d’intrants chimiques</b>, notamment les engrais chimiques dont l’épandage génère de l’ammoniac (premier précurseur de particules fines après le secteur automobile).
      </p>
      <h4>Part de repas végétariens</h4>
      <p>
        La limitation de la consommation de produits animaux participent à la réduction des pollutions de l’air du fait d’une <b class="positif">moindre production de fumier et de lisier</b>, émetteurs d’ammoniac.
      </p>
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'RESSOURCES ÉNERGÉTIQUES & MATÉRIAUX'">
      <h3>État des lieux</h3>
      <p>
        Le coût de l’énergie représente <b class="">16% du coût de fonctionnement</b>.
      </p>
      <h3>IMPACTS DES caractéristiques de la cantine</h3>
      <h4>Liaison froide</h4>
      <p>
        La liaison froide entraîne une <b class="negatif">consommation d’énergie pour refroidir les repas produits, pour les conserver puis pour les remettre à température</b> ce qui augmente la dépendance aux ressources énergétiques.
      </p>
      <h3>Impact des leviers de transition</h3>
      <h4>Part de produits bio</h4>
      <p>
        La bio, du fait d'une moindre utilisation de produits de synthèse, <b class="negatif">nécessite moins de ressources pour en produire</b>.<br>
        Mais la <b class="negatif">mécanisation est plus importante</b> en bio et donc augmente la dépendance aux ressources énergétiques.
      </p>
      <h4>Réduction du gaspillage</h4>
      <p>
        25% de l’énergie utilisée l’est pour des quantités gaspillées. Ainsi la réduction du gaspillage permet de <b class="positif">réduire l’énergie utilisée</b>.<br>
        Vous pouvez observer l’impact de la réduction de gaspillage sur les coûts en cliquant sur retour.
      </p>
      <h4>Cuisson basse température</h4>
      <p>
        La cuisson basse température <b class="positif">peut permettre de faire des économies d’énergie</b> (cependant cela dépend de la déperdition énergétique du four utilisé). L’utilisation de la cuisson basse température n’a pas été modélisé dans les coûts comptables. 
      </p>
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'SANTÉ HUMAINE'">
      <h3>État des lieux</h3>
      <p>
        La restauration scolaire permet aux convives d’avoir accès à une alimentation équilibrée.<br>
        L’alimentation est une thématique majeure de santé publique. Aujourd’hui, <b class="negatif">17% des enfants sont en surpoids, dont 4% sont obèses</b>.<br>
        Il existe plusieurs maladies professionnelles (cf enjeux des conditions de travail)
      </p>
      <h3>Impact des leviers de transition</h3>
      <h4>Part de produits bio</h4>
      <p>
        Les produits bio permettent de <b class="positif">réduire les risques d’exposition de la population aux pesticides</b>, que ce soit pour les agriculteurs, les riverains ou les consommateurs via les résidus de pesticides dans les aliments.
      </p>
      <h4>Cuisson basse température et produits bruts</h4>
      <p>
        La cuisson basse température ainsi que les produits bruts permettent de <b class="positif">conserver une meilleure qualité nutritionnelle des aliments</b>.
      </p>
      <h4>Interdiction du plastique</h4>
      <p>
        Le remplacement des contenants plastiques permet d’<b class="positif">éviter les risques de contamination des aliments</b>, notamment lorsqi’ils sont réchauffés.<br>
        Cependant le remplacement des contenants par des contenants plus lourds augmente le <b class="negatif">risque de troubles musculosquelettiques pour les agents de restauration</b>.
      </p>
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'DÉCHETS'">
      <h3>État des lieux</h3>
      <p>
        En moyenne, le gaspillage représente <b class="">16 % du coût des denrées et 18 % en masse</b>. Il y a des différences selon les types de structure, les pertes et gaspillage représentent <b class="">14 % des quantités en cuisine sur place</b> et <b class="">25 % en restaurant satellite</b>.
      </p>
      <h3>Impact des leviers de transition</h3>
      <h4>Réduction du gaspillage</h4>
      <p>
        La réduction du gaspillage permet de réduire les coûts (que vous pouvez voir dans le détail en cliquant sur le bouton retour).
      </p>
      <h4>Ensemble des leviers de transition</h4>
      <p>
        Le changement de régime alimentaire peut induire une <b class="negatif">augmentation du gaspillage alimentaire si l’introduction de nouveaux menus n’est pas accompagnée par une éducation à l’alimentation</b>.
      </p>
    </div>

    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'ÉQUITÉ SOCIO-ÉCONOMIQUE'">
      <h3>État des lieux</h3>
      <p>
        La tarification peut représenter une <b class="negatif">source d’inégalités selon la dégressivité des tarifs</b>. Pour plus d’informations, vous pouvez aller sur le module “Recettes”
      </p>
    </div>

    
    <div v-if="problematique_selectionnee.libelle_problematique_positif == 'DÉMOCRATIE ALIMENTAIRE'">
      <h3>État des lieux</h3>
      <p>
        La restauration scolaire permet de rendre accessible une alimentation équilibrée à l'ensemble des convives qui le souhaitent. Cependant, selon la tarification proposée par la restauration, le choix peut ne pas en être réellement un.
      </p>
       <h3>Impact des leviers de transition</h3>
      <p>
        La loi EGALim impose aux restaurations scolaires d'informer les convives et leurs familles du contenu des repas, mais aussi de la part de produits durables et la part de produits locaux. Ainsi cela participe à <b class="positif">renforcer l'information des consommateurs sur leur alimentation</b>.
      </p>
    </div>
  </div>
  </div>
  </div> 
</template>
<script>


import champs from '../json/champs.json'
import problematiques from '../json/problematiques.json'
import enjeux from '../json/enjeux.json'
import basicApi from '@/repositories/basic_api.js'


export default {
  name: 'VisualisationDoughnut',
  data() {

    var nos_champs = {}
    var sauvegarde = JSON.parse(localStorage.getItem('parametres')) || {}
    console.log('Sauvegarde : ',sauvegarde)
    champs.forEach(c=>{
      if(Object.prototype.hasOwnProperty.call(sauvegarde, c.parametre)) {
        c.valeur = sauvegarde[c.parametre].valeur || c.valeur_par_defaut
      } else {
        c.valeur = c.valeur_par_defaut
      }
      nos_champs[c.parametre] = c
      // return c
    })


    return {
      champs: nos_champs,

      problematiques : problematiques.map(p=>{
        p.image = 'url("images/icones-problematiques/'+p.image+'")'
        p.decalage = p.categorie_problematique == 'Social' ? -Math.PI/7 : 0
        p.multiplicateur_rayon = p.categorie_problematique == 'Social' ? 0.6 : 1
        p.enjeux = enjeux['Déterminants'].filter(e=> e.code_problematique == p.code_problematique)
        return p
      }),

      chiffres : [],

      enjeux: enjeux['Déterminants'],
      problematique_selectionnee : { code_problematique: undefined },
      pi: Math.PI,
      cos: Math.cos,
      sin: Math.sin,
      decalage: 0,
      rayon: '250px',
      centre: '300px'

    };
  },
  created: function() {


  },
  mounted(){
    this.recuperer_la_decoupe_de_valeur()

    
  },
  watch: {

  },
  methods: {
    recuperer_la_decoupe_de_valeur: function(){
      var parametres = Object.fromEntries(Object.values(this.champs).filter(c=>c.valeur != undefined || c.valeur !== null).map(c=>[c.parametre,c.valeur]))
      console.log('Paramètres :',parametres)
      basicApi.recuperer_la_decoupe_de_valeur(parametres)
      .then(res => {
        this.chiffres = res.data
        window.chiffres = res.data
        console.log('Données récupérées',this.chiffres)
        
        // console.log('Etapes :',this.etapes)

      })
    },
    selectionner(problematique){
      console.log('selection',problematique)

      this.problematique_selectionnee = problematique
    },
    valeur(chiffre,arrondi){
      arrondi = arrondi || 0
      if(chiffre != undefined){
        var retour = Math.round(chiffre.valeur * Math.pow(10,arrondi)) / Math.pow(10,arrondi)
        console.log('arrondi',chiffre.valeur,retour,arrondi)
        return retour
      }
      return ''
    }
  }
}
</script>

