<style scoped>
#parlez-ami{
  position: fixed;
  z-index: 100000;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
}
.connexion{
  margin-left: 30%;
  position: relative;
  margin-top: 20%;
}
input{
  width: 15rem;
}
.texte_erreur{
  color : rgb(99, 0, 0);
  font-style: italic;
  max-width: 25rem;
}
</style>


<template>
  <div v-if="!ouvert" id="parlez-ami">
    <div class="connexion">
      <h1>Restauration scolaire : quels effets des leviers de transition sur le coût d'un repas ?</h1>
      <br>
      <p>Entrez votre adresse mail pour accéder à l'outil :</p>
      <input type="email" v-model="email"/>
      <br><br>
      <button @click="entrer">Entrer</button>
      <p class="texte_erreur">{{ texte_erreur }}</p>
    </div>
  </div>
</template>

<script>

String.prototype.hashCode = function() {
  var hash = 0,
    i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}



export default {
  name: 'parlez-ami',
  data() {
    return {
      ouvert: false,
      email: '',
      adresse_autorisees: [-1106221009,412952392,2102666982,1074701168,-452380622,-361554413,-461410519,446318165,-547056783,1216751410,-984958728,1677402183,-743960465,1549817530,1941946902,2127649659,2048135494,1462704058,-635243855,1936355758,185965223,1613716555,-863943318,1541760740,114866615,-78163778,-1409940512,580240387,225457478,71244581,-532534497,336032919,-839951894,270315475,-1786464555,-1124197428,-471447858,-1643724039,-1412346183,2050551562,-2026620761,-1416675740,2117572415,-286000263,490516528,836675203,844190206,1247186873,-1822658161,-1515854047,-1134593053,-1268066030,-44189808],
      texte_erreur : ''
    }
  },
  mounted(){
    if(localStorage.ouvert == 'true'){
      this.ouvert = true
    }

  },
  watch: {
  
  },
  methods: {
    entrer: function(){
      if(this.adresse_autorisees.includes(this.email.toLowerCase().hashCode())){
        this.ouvert = true
        if(this.email.includes('@lebasic.com')){
          localStorage.ouvert = 'true'
        }
      } else {
        this.texte_erreur = "Vous n'avez pas l'autorisation d'accéder à l'application. Contactez Camille Brillion pour demander l'accès."
      }
    },
    convertir_en_hashcode : function(liste_adresses_mail){
      return liste_adresses_mail.map(a => a.toLowerCase().hashCode()).join(',')
    }
  }
}
</script>
